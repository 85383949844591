@import "../../variables";

.content {
    padding: 16px 8px 0 16px;
    display: flex;
    flex-wrap: wrap;

    .info {
        display: flex;
        flex-direction: column;

        > * {
            padding: 8px 0;

        }
    }

    .receipt{
        max-height: 60vh;
        overflow-y: auto;
        margin-left: 16px;
        padding-left: 16px;
        border-left: solid 1px var(--color-font-disabled);

        img{
            max-width: 25vw;
        }
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        flex-direction: column;

        .receipt{
            margin: 16px 0;
            padding: 0;
            border: none;
            max-height: 45vh;

            img{
                max-width: 100%;
            }
        }
    }
}

.actions {
    display: flex;
    justify-content: end;
    margin: 24px 8px 0 0;

    > *:not(:first-child) {
        margin-left: 8px;
    }
}