.baseFormUser {
    width: 300px;

    .field {
        margin-bottom: 16px;
    }

    .actions {
        margin-top: 32px;
        display: flex;
    }
}
