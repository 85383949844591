.orderTable{
    padding-top: 16px;

    table{
        width: 100%;

        tbody tr{
            cursor: pointer;
        }
    }
}