@import '../../../variables';

.login {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    height: 100%;

    .logo {
        margin-bottom: 48px;
        max-width: 300px;
        text-align: center;

        @media (prefers-color-scheme: dark) {
            filter: invert(1);
        }

        img{
            max-width: 100%;
        }
    }

    .signInButton {
        margin: 16px 0;
    }

    .alert {
        position: relative;
        cursor: default;

        margin: 8px 0;
        padding: 8px 16px;

        font-size: $fontSize;
        text-align: center;
        color: white;
        background-color: var(--color-error);
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3)  {
        .logo img{
            width: 70%;
        }
    }
}
