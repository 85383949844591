@import "../../mixins";

.clients{
    .tableClients {
        table {
            width: 100%;

            td, th {
                &:last-child {
                    width: 3%;
                }
            }

            tr td {
                cursor: pointer;

                &:nth-child(2){
                    text-transform: capitalize;
                }

                .price {
                    display: flex;
                    flex-direction: column;

                    span {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        @include hideCard;
        margin-bottom: 32px;
    }
}
