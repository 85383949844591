.approveOrderReceipt{
    padding: 8px 0;

    >*{
        padding: 8px 0;
    }

    .actions{
        display: flex;
        justify-content: end;

        >*{
            margin-left: 8px;
        }
    }
}