.chooseOptions{
    .title{
        padding-left: 8px;
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 4px;
        opacity: .5;
    }

    .options{
        padding-top: 8px;
        display: flex;

        .optionItem{
            cursor: default;

            &:not(:first-child){
                padding-left: 16px;
            }

            &.active span{
                font-weight: bold;
            }

            span{
                padding-left: 4px;

                font-size: 14px;
                font-weight: 400;
                opacity: .75;
            }
        }
    }
}

