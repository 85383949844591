@import "../../mixins";

.settings {
    h3 {
        margin-top: 40px;
    }

    .images {
        display: flex;
        margin-bottom: 24px;
    }

    .imageBtn{
        display: flex;
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3)  {
        @include hideCard;

        .discardButton{
            display: none;
        }
    }
}
