@import './variables';
@import './mixins';

:root {
  --radius-default: 4px;
  --radius-whole-app: 8px;
  --radius-card: 8px;
  --padding-content: 16px 24px;
  --font-size-densed: 14px;
  --color-font-on-primary: white;
  --color-primary-active: #30305c;
  --color-button-error: #7e0d0d;
  --color-button-error-hover: #724958;
  --color-success: rgb(22, 132, 23);

  @media (prefers-color-scheme: dark) {
    --color-primary: rgb(41, 41, 41);
    --color-primary-dark: rgb(41, 41, 41);
    --color-primary-light: rgba(194, 194, 194, 0.07);
    --color-primary-lighter: #121212;
    --color-primary-hover: #33CC33;
    --color-selected: #69ec69;
    --color-background-light: #1c1b1b;
    --color-background-dialog: #121212;
    --color-primary-button: #288f28;
    --color-primary-disabled: #448344;
    --color-primary-button-hover: rgba(95, 65, 123, 0.17);
    --color-font: #898989;
    --color-font-input: #c7c7c7;
    --color-font-active: white;
    --color-font-disabled: #646464;
    --color-font-error-disabled: #a82350;
    --color-title: white;
    --color-background-field: rgb(43, 43, 43);
    --color-error: #df3b71;
    --color-border-field: rgba(194, 194, 194, 0.19);
    --color-transaction: rgb(41, 41, 41);
    --color-icon-button-hover: rgba(194, 194, 194, 0.19);
    --color-body: #121212;
    --color-app-bar: #1f1f1f;
    --color-button-outline: #47c547;
    --color-switch-background: rgba(68, 131, 68, 0.36);
    --color-switch-off: rgba(194, 194, 194, 0.19);
    --color-switch-background-off: rgb(193 193 193);
    --color-search-result: #343434;
  }

  @media (prefers-color-scheme: light) {
    --color-app-bar: white;
    --color-primary: #30305c;
    --color-primary-dark: #1a1a31;
    --color-primary-darker: #070d23;
    --color-primary-light: #dde3ef;
    --color-primary-lighter: #eaeaf3;
    --color-primary-disabled: #c7c7e0;
    --color-primary-hover: #242445;
    --color-primary-button: #30305c;
    --color-primary-button-hover: white;
    --color-selected: #30305c;
    --color-accent: rgb(31, 61, 38);
    --color-background-light: white;
    --color-background-dialog: white;
    --color-error: rgb(240, 32, 32);
    --color-font-on-primary-faded: rgba(255, 255, 255, 0.7);
    --color-font: rgb(36, 46, 52);
    --color-font-input: rgb(21, 28, 31);
    --color-icon-button-hover: rgb(220, 220, 220);
    --color-switch-background: #d4d4f8;
    --color-background-field: rgb(241, 241, 241);
    --color-border-field: rgb(194, 194, 194);
    --color-body: #ffffff;
    --color-font-active: #070d23;
    --color-font-disabled: #bdbdbd;
    --color-button-outline: #242445;
    --color-switch-off: #eaeaf3;
    --color-switch-background-off: white;
    --color-search-result: white;
    --color-transaction: white;
  }
}

body,
html,
#root {
  margin: 0;
  padding: 0;
  color: var(--color-font);
  background: var(--color-body);
  height: 100%;
}

@font-face {
  font-family: Biotif;
  font-weight: 200;
  src: local('Biotif-Book'), url('assets/fonts/Biotif-Book.ttf') format('truetype');
}

@font-face {
  font-family: Biotif;
  font-weight: 300;
  src: local('Biotif-Regular'), url('assets/fonts/Biotif-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Biotif;
  font-weight: 400;
  src: local('Biotif-Medium'), url('assets/fonts/Biotif-Medium.ttf') format('truetype');
}

@font-face {
  font-family: Biotif;
  font-weight: 500;
  src: local('Biotif-Bold'), url('assets/fonts/Biotif-Bold.ttf') format('truetype');
}

@font-face {
  font-family: Biotif;
  font-weight: 600;
  src: local('Biotif-ExtraBold'), url('assets/fonts/Biotif-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: Biotif;
  font-weight: 700;
  src: local('Biotif-Black'), url('assets/fonts/Biotif-Black.ttf') format('truetype');
}

@font-face {
  font-family: Inter;
  font-weight: 100;
  src: local('Thin'), url('assets/fonts/Inter-Thin.ttf') format('truetype');
}

@font-face {
  font-family: Inter;
  font-weight: 200;
  src: local('ExtraLight'), url('assets/fonts/Inter-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: Inter;
  font-weight: 300;
  src: local('Light'), url('assets/fonts/Inter-Light.ttf') format('truetype');
}

@font-face {
  font-family: Inter;
  font-weight: 400;
  src: local('Regular'), url('assets/fonts/Inter-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Inter;
  font-weight: 500;
  src: local('Medium'), url('assets/fonts/Inter-Medium.ttf') format('truetype');
}

@font-face {
  font-family: Inter;
  font-weight: 600;
  src: local('SemiBold'), url('assets/fonts/Inter-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: Inter;
  font-weight: 700;
  src: local('Bold'), url('assets/fonts/Inter-Bold.ttf') format('truetype');
}

@font-face {
  font-family: Inter;
  font-weight: 800;
  src: local('ExtraBold'), url('assets/fonts/Inter-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: Inter;
  font-weight: 900;
  src: local('Black'), url('assets/fonts/Inter-Black.ttf') format('truetype');
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Biotif, sans-serif;
  font-weight: 600;
  margin: 0;
  color: var(--color-title);
}

span,
div,
a,
p {
  font-family: Inter, sans-serif;
  font-weight: 400;
  margin: 0;
  color: var(--color-title);
}

.link, a{
  cursor: pointer;
  text-decoration: none;

  color: var(--color-primary-button);
  font-weight: bold;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input[type='field']{

}

table {
  border-collapse: collapse;

  tbody tr:nth-child(odd) td {
    background: var(--color-background-field);
  }

  thead th {
    padding: 16px 0;
  }

  td {
    font-size: 12px;
    padding: 8px 16px;

    font-weight: 500;
    text-align: center;
  }
}

$borderTable: var(--radius-default);

tr:first-child th:first-child {
  border-top-left-radius: $borderTable;
}

tr:first-child th:last-child {
  border-top-right-radius: $borderTable;
}

tr:last-child td:first-child {
  border-bottom-left-radius: $borderTable;
}

tr:last-child td:last-child {
  border-bottom-right-radius: $borderTable;
}


@media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
  table {
    display: inline;

    thead {
      display: none;
    }

    tbody {
      display: flex;
      flex-direction: column;

      tr {
        @include baseCard;

        display: flex;
        flex-direction: column;

        width: 100%;
        position: relative;
        margin: 16px 0;
        padding: 8px 0;

        &:nth-child(odd) td {
          background: var(--color-background-light);
        }

        td {
          display: inline;
          text-align: left;

          &:before {
            content: attr(data-title);
            @include labelTitle;
            color: var(--color-font);
          }
        }
      }
    }
  }
}

.success{
  color: var(--color-success);
}

.error{
  color: var(--color-error)
}