@import "../../../../variables";

.clientEdit{
    max-width: 260px;

    >*{
        padding-bottom: 8px;

        &:first-child{
            margin-top: 24px;
        }
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {

        padding: 16px;
        max-width: unset;
    }
}