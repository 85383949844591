.rejectOrderReceipt {
  padding: 8px 0;
  width: 400px;

  .actions {
    display: flex;
    justify-content: end;
    padding-top:8px;

    > * {
      margin-left: 8px;
    }
  }
}
