.pickBank{
    display: flex;

    .bankItem{
        width: 96px;
        padding: 8px;
        height: 70px;
        border: var(--color-font) 1px solid;
        border-radius: 8px;
        margin: 8px;

        display: flex;
        align-items: center;

        cursor: pointer;

        transition: border-color 200ms ease-in-out;

        img{
            width: inherit;
        }

        &:hover{
            border-color: var(--color-font-input);
        }

        &.active{
            border-color: var(--color-selected)
        }

    }
}