@import "../../../mixins";

.selectField {
    position: relative;
    cursor: pointer;

    margin: 8px 0;
    padding: 8px;
    border-bottom: 1px solid var(--color-border-field);

    transition: background-color, border-color 0.4s;
    background: var(--color-background-light);

    .label {
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 4px;
        opacity: .5;
    }

    > span {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:after {
            content: ' ';

            $size: 8px;
            width: 0;
            height: 0;
            border-left: $size solid transparent;
            border-right: $size solid transparent;

            border-top: $size solid var(--color-primary);
        }
    }

    .selectFieldOptions {
        @include elevation;

        display: none;
        width: 100%;
        background: var(--color-transaction);
        z-index: 1;
        position: absolute;
        left: 0;
        top: 8px;

        border-radius: var(--radius-default);

        span {
            padding: 8px;
            display: block;

            &:hover{
                background: var(--color-icon-button-hover);
            }

            &.active {
                background: var(--color-border-field);
                color: var(--color-selected);
                font-weight: 500;
            }
        }
    }

    &.open .selectFieldOptions {
        display: unset;
    }
}
