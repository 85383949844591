@import '../../../mixins';
@import '../../../variables';

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.searchBar {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    padding: 8px 16px;

    background: var(--color-background-field);
    border-radius: var(--radius-default);
    border: 1px solid transparent;

    $animationDuration: 0.9s;
    transition: border-color $animationDuration;

    &:focus-within {
        border-color: var(--color-border-field);
        .componentSearch{
            display: unset;
        }

        img {
            filter: invert(0.3);
        }
    }

    .componentSearch {
        &:hover{
            display: unset;
        }
        @include elevation;
        animation: fade-in .3s forwards;
        display: none;

        padding: 8px 0;

        width: 100%;
        max-height: 240px;
        position: absolute;
        overflow: auto;
        z-index: 1;

        right: 0;
        top: calc(100% + 8px);
        background: var(--color-background-light);
        border-radius: var(--radius-card);

        &.noResult{
            display: block;
            height: 240px;
            span {
                display: block;
                position: relative;
                font-weight: 400;
                color: gray;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .resultSearchItem {
            display: flex;
            padding: 8px 16px;
            text-align: left;
            cursor: pointer;
            background: var(--color-search-result);

            img {
                margin-right: 8px;
            }

            .resultInfo >*{
                width: inherit;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;

                &:last-child {
                    opacity: 0.6;
                    font-size: 12px;
                    margin-top: 4px;
                }
            }

            &:hover {
                background: var(--color-icon-button-hover);
            }
        }
    }

    input {
        margin-left: 16px;
        border: none;
        outline: none;
        background: var(--color-background-field);
        width: 30vw;
        opacity: 0.5;
        color: var(--color-font-input);

        &:focus-within {
            opacity: 1;
        }
    }

    img {
        transition: filter $animationDuration;
        filter: invert(0.5);
    }

    .icon {
        display: inherit;
        position: absolute;
        right: 0;
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3)  {
        input{
            width: 40vw;
        }
    }
}
