.button {
    padding: 8px 16px;
    text-transform: uppercase;
    border-radius: var(--radius-default);
    font-weight: 500;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    transition: all .08s linear, min-width .15s cubic-bezier(0.4, 0.0, 0.2, 1);

    .loading{
        width: 60px;
        display: flex;
        justify-content: center;
    }

    &.primary {
        box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 1px 3px 1px rgba(60, 64, 67, 0.149);
        background: var(--color-primary-button);
        color: white;

        &.disabled {
            background: var(--color-primary-disabled);
            color: rgb(235, 231, 231);
            cursor: default;
        }

        &:hover {
            &:not(.disabled) {
                background: var(--color-primary-hover);
                box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.302), 0 4px 8px 3px rgba(60, 64, 67, 0.149);
            }
        }

        &.errorColor{
            background: var(--color-error);

            &.disabled {
                color: var(--color-font-error-disabled);
            }

            &:hover:not(.disabled) {
                background: var(--color-button-error-hover);
            }
        }

        @media (prefers-color-scheme: dark){
            img {
                filter: invert(0.5);
            }
        }
    }

    &.outline {
        &.primaryColor{
            border: 1px solid var(--color-button-outline);
            color: var(--color-button-outline);

            &:hover {
                background: var(--color-primary-button-hover);
            }
        }

        &.errorColor{
            border: 1px solid var(--color-error);
            color: var(--color-error);

            &:hover {
                background: var(--color-button-error-hover);
            }
        }

        @media (prefers-color-scheme: dark){
            img {
                filter: invert(0.7);
            }
        }
    }

    &.text {
        padding: 0;
        border: none;
        color: var(--color-accent);
        text-transform: none;

        &:hover {
            text-decoration: underline;
        }
    }

    &.error {
        background: var(--color-button-error);
        color: white;

        &:hover {
            background: var(--color-button-error-hover);
        }

    }
}
