@import '../../../variables';
@import "../../../mixins";

.menu {
    min-width: 200px;
    width: 13%;
    background: var(--color-primary-dark);

    height: 100%;

    border-bottom-left-radius: var(--radius-whole-app);
    border-top-left-radius: var(--radius-whole-app);

    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 1px 3px 1px rgba(60, 64, 67, 0.149);

    header {
        margin: 24px;

        img {
            max-width: 70%;

            @media (prefers-color-scheme: dark) {
                filter: invert(1);
            }
        }

        h3 {
            color: rgba(255, 255, 255, .9);
        }
    }

    .routes {
        margin: 80px 16px;

        > a {
            padding: 8px 16px;

            display: flex;
            align-items: center;
            border-radius: var(--radius-default);
            cursor: pointer;

            text-decoration: none;
            margin: 8px 0;

            img {
                filter: invert(.7);
            }

            span {
                color: var(--color-font-on-primary);
                padding-left: 16px;
            }

            &:hover {
                background: var(--color-primary-hover);
            }

            &.active {
                background: var(--color-primary-hover);
                cursor: default;

                img {
                    filter: invert(1);
                }

                span {
                    color: rgba(255, 255, 255, .9);
                    font-weight: bold;
                }
            }
        }
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        position: fixed;
        z-index: 1;
        width: 70%;
        left: 0;

        transition-duration: .3s;
        transition-property: left;
        transition-timing-function: ease-in-out;

        border-top-left-radius: unset;
        border-top-right-radius: var(--radius-whole-app) ;

        border-bottom-left-radius: unset;
        border-bottom-right-radius: var(--radius-whole-app) ;

        &:not(.isOpened) {
            left: -100%;
        }

        header img {
            max-width: 50%;
        }
    }
}
