@import '../src/variables';

@mixin page{
    padding: var(--padding-content);
}

@mixin elevation{
    @media (prefers-color-scheme: light) {
        box-shadow: 0 1px 3px 0 rgba(60,64,67,0.302),0 4px 8px 3px rgba(60,64,67,0.149);
    }
}

@mixin elevation2x{
    @media (prefers-color-scheme: light) {
        box-shadow:  0 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12);
    }
}

@mixin baseCard {
    @include elevation;

    margin: $sizeMarginCard;
    border-radius: var(--radius-card);
    background: var(--color-background-light);
}

@mixin centered {
    position: relative;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
}

@mixin labelTitle{
    display: block;
    font-size: 8px;
    font-weight: 500;

    opacity: .7;
}

@mixin hideCard{
    margin: 0;
    padding: 0;
    background: transparent !important;
    box-shadow: none;

    h3 {
        padding-bottom: 0;
    }
}

@mixin lastTdAsAction{
    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        tbody tr td:last-child{
            border-top-right-radius: var(--radius-card);

            position: absolute;
            top: 0;
            right: 0;
        }
    }
}

@mixin backgroundBlur{
    content: ' ';
    width: 100vw;
    height: 100vh;
    height: -webkit-fill-available;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;

    background-color: rgba(20, 20, 20, 0.8);
    @supports ((-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))) {
        backdrop-filter: blur(4px);
        background-color: rgba(200, 200, 200, .8);
    }
}